import React, { Component } from "react"
import "./Modal.styles.css"

export default class Modal extends Component {
  render() {
    const { children } = this.props
    return (
      <div className="modal" onClick={this.props.onDismiss}>
        <div
          className="modal__content"
          onClick={e => {
            e.stopPropagation()
          }}
        >
          {children}
        </div>
      </div>
    )
  }
}

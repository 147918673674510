import React from "react"
import Helmet from "react-helmet"
import Layout from "../components/Layout/Layout"
import ga from "react-ga"
import gtm from "react-gtm-module"
import { useStaticQuery } from "gatsby"

const helmetTitle = (site, page) =>
  page.seoTitle
    ? `${site.title} | ${page.seoTitle}`
    : page.name !== "Home"
    ? `${site.title} | ${page.name}`
    : site.title

export default function LayoutContainer({ children, site, page, location }) {
  React.useEffect(() => {
    ga.initialize(site.seoGoogleAnalytics)
    ga.pageview(location.pathname)
    gtm.initialize({ gtmId: site.seoGoogleTagManager })
  }, [location, site.seoGoogleAnalytics, site.seoGoogleTagManager])

  return (
    <div>
      <Helmet
        title={helmetTitle(site, page)}
        meta={[
          {
            name: "description",
            content:
              (page.seoDescription && page.seoDescription.seoDescription) || "",
          },
          {
            name: "keywords",
            content: page.seoKeywords ? page.seoKeywords.join(", ") : "",
          },
        ]}
      />
      <Layout
        path={location.pathname}
        pages={site.pages}
        contact={{
          addressLineOne: site.contactAddressLineOne,
          addressLineTwo: site.contactAddressLineTwo,
          email: site.contactEmail,
          telephone: site.contactTelephone,
        }}
      >
        {children}
      </Layout>
    </div>
  )
}

import React from "react"

import Section from "../Section/Section"

import "./SectionBanner.styles.css"

const SectionBanner = ({ children, ...props }) => {
  return (
    <Section {...props}>
      <div className="section-banner">{children}</div>
    </Section>
  )
}

export default SectionBanner

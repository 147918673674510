import React from "react"
import LayoutHeader from "./Layout.Header"
import Wrapper from "../Wrapper/Wrapper"

import "./Layout.styles.css"

const Layout = ({ path, pages, contact, children }) => {
  return (
    <div className="layout">
      <Wrapper>
        <LayoutHeader path={path} pages={pages} />
      </Wrapper>

      <main className="layout--main">{children}</main>

      <Wrapper>
        <footer className="layout--footer">
          <p>{contact.addressLineOne}</p>
          <p>{contact.addressLineTwo}</p>
          <p>
            <br />
          </p>
          <p>T {contact.telephone}</p>
          <p>{contact.email}</p>
        </footer>
      </Wrapper>
    </div>
  )
}

export default Layout

import "./Section.styles.css"
import React from "react"

import Wrapper from "../Wrapper/Wrapper"
import { StickyContainer, Sticky } from "react-sticky"

import cc from "classcat"

const Section = ({ title, headerContent, stickyHeader, children }) => {
  const headerEl = (!!title || !!headerContent) && (
    <Wrapper>
      <header className="section--header">
        {title && <h1 className="section--header--title">{title}</h1>}
        {headerContent && (
          <div className="section--header--content">{headerContent}</div>
        )}
      </header>
    </Wrapper>
  )

  const mainEl = (
    <div className="section--main">
      <Wrapper>
        <div className="layout--main--inner">{children}</div>
      </Wrapper>
    </div>
  )

  if (!stickyHeader) {
    return (
      <section className="section">
        {headerEl}
        {mainEl}
      </section>
    )
  }

  return (
    <section className="section">
      <StickyContainer>
        <Sticky>
          {({ style, isSticky }) => (
            <div
              style={style}
              className={cc({
                "section--header-wrapper": true,
                "m--fixed": isSticky
              })}
            >
              {headerEl}
            </div>
          )}
        </Sticky>
        {mainEl}
      </StickyContainer>
    </section>
  )
}

export default Section

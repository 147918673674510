let THREE
if (typeof document !== undefined) {
  THREE = require("three")
}

/**
|--------------------------------------------------
| Helpers
|--------------------------------------------------
*/

/**
 * Number degrees to radians.
 */

const deg = deg => deg / 90 * Math.PI * 0.5

/**
 * Paint an image with rotation
 */

const drawImage = (ctx, image, deg, x, y, width, height) => {
  ctx.translate(x + width * 0.5, y + height * 0.5)
  ctx.rotate(deg)
  ctx.drawImage(image, width * -0.5, height * -0.5, width, height)
  ctx.rotate(-deg)
  ctx.translate((x + width * 0.5) * -1, (y + height * 0.5) * -1)
}

/**
|--------------------------------------------------
| Tiled Canvas
|--------------------------------------------------
*/

export const setupTiledCanvas = (
  { width, depth, cornerImage, sideImage, centerImage, rotate },
  onUpdate
) => {
  console.log(cornerImage, sideImage, centerImage)
  /**
   * Resolution
   */

  const res = 256

  /**
   * Setup canvas element
   */

  const canvas = document.createElement("canvas")
  canvas.width = width * res
  canvas.height = depth * res

  /**
   * Requirements
   */

  if (!canvas.getContext) {
    return null
  }

  /**
   * Draw images
   */

  const ctx = canvas.getContext("2d")

  ctx.fillStyle = "#dadada"
  ctx.fillRect(0, 0, width * res, depth * res)

  // (size * resolution)
  const size = 0.2 * res
  const tileX = width / 0.2
  const tileY = depth / 0.2

  let loaded = 0

  const draw = () => {
    if (loaded !== 2) {
      loaded++
      return
    }

    for (let i = 0; i < tileX; i++) {
      for (let j = 0; j < tileY; j++) {
        let img = center
        let rot = 0

        if ((i === 0 || i === tileX - 1) && (j === 0 || j === tileY - 1)) {
          img = corner
          rot = i === 0 && j === 0 ? 0 : i === 0 ? -90 : j === 0 ? 90 : 180
        } else if (i === 0 || i === tileX - 1 || j === 0 || j === tileY - 1) {
          img = side
          rot = i === 0 ? -90 : j === 0 ? 0 : j === tileY - 1 ? 180 : 90
        } else {
          img = center
          rot = !rotate ? 0 : i % 2 ? (j % 2 ? 270 : 180) : j % 2 ? 0 : 90
        }

        drawImage(ctx, img, deg(rot), size * i, size * j, size, size)
      }
    }

    onUpdate && onUpdate()
  }

  let corner = new Image()
  corner.src = cornerImage
  corner.crossOrigin = "Anonymous"
  corner.onload = draw

  let side = new Image()
  side.src = sideImage
  side.crossOrigin = "Anonymous"
  side.onload = draw

  let center = new Image()
  center.src = centerImage
  center.crossOrigin = "Anonymous"
  center.onload = draw

  return canvas
}

/**
|--------------------------------------------------
| Room Canvas
|--------------------------------------------------
*/

/**
 * Util for creating a mesh with position and texture.
 */

const mesh = ({ w, h, c, x, y, z, rX, rY, rZ, texture, opacity }) => {
  if (!THREE) return

  let mesh = new THREE.Mesh(
    new THREE.PlaneGeometry(w, h),
    new THREE.MeshBasicMaterial({
      color: c,
      map: texture || null,
      side: THREE.DoubleSide,
      transparent: !!opacity,
      opacity: !!opacity ? opacity : 1.0
    })
  )

  mesh.position.x = x || 0
  mesh.position.y = y || 0
  mesh.position.z = z || 0
  mesh.rotation.x = rX ? deg(rX) : 0
  mesh.rotation.y = rY ? deg(rY) : 0
  mesh.rotation.z = rZ ? deg(rZ) : 0

  return mesh
}

/**
 * Get meshes for room simulator
 */

const getMeshes = ({
  width,
  height,
  depth,
  outer,
  wallColor,
  wallDarkerColor,
  innerColor,
  floorColor,
  floorTexture
}) => ({
  wallBack: mesh({
    w: width,
    h: height,
    c: wallColor,
    y: height * 0.5
  }),
  wallBackInner: mesh({
    w: width + outer * 2,
    h: outer,
    c: innerColor,
    y: height,
    z: outer * -0.5,
    rX: -90
  }),
  wallLeft: mesh({
    w: depth,
    h: height,
    c: wallColor,
    x: width * -0.5,
    y: height * 0.5,
    z: depth * 0.5,
    rY: 90
  }),
  wallLeftShadow: mesh({
    w: depth,
    h: height,
    c: 0x000000,
    opacity: 0.05,
    x: width * -0.5,
    y: height * 0.5,
    z: depth * 0.5,
    rY: 90
  }),
  wallLeftInnerUpper: mesh({
    w: depth,
    h: outer,
    c: innerColor,
    x: (outer + width) * -0.5,
    y: height,
    z: depth * 0.5,
    rX: -90,
    rZ: 90
  }),
  wallLeftInner: mesh({
    w: outer,
    h: height,
    c: innerColor,
    x: (outer + width) * -0.5,
    y: height * 0.5,
    z: depth
  }),
  wallRight: mesh({
    w: depth,
    h: height,
    c: wallColor,
    x: width * 0.5,
    y: height * 0.5,
    z: depth * 0.5,
    rY: -90
  }),
  wallRightShadow: mesh({
    w: depth,
    h: height,
    c: 0x000000,
    opacity: 0.03,
    x: width * 0.5,
    y: height * 0.5,
    z: depth * 0.5,
    rY: -90
  }),
  wallRightInnerUpper: mesh({
    w: depth,
    h: outer,
    c: innerColor,
    x: (outer + width) * 0.5,
    y: height,
    z: depth * 0.5,
    rX: -90,
    rZ: 90
  }),
  wallRightInner: mesh({
    w: outer,
    h: height,
    c: innerColor,
    x: (outer + width) * 0.5,
    y: height * 0.5,
    z: depth
  }),
  floor: mesh({
    w: width,
    h: depth,
    c: floorColor,
    z: depth * 0.5,
    rX: -90,
    texture: floorTexture
  }),
  floorInner: mesh({
    w: width + outer * 2,
    h: outer,
    c: innerColor,
    y: outer * -0.5,
    z: depth
  })
})

export function setupRoomCanvas(props, canvas, tiledCanvas) {
  /**
   * Requirements
   */

  if (!canvas || !THREE) {
    return null
  }

  /**
   * Floor Texture
   */

  const floorTexture = new THREE.CanvasTexture(tiledCanvas)
  // const floorTexture = null

  /**
   * Scene
   */

  const scene = new THREE.Scene()
  const canvasWidth = canvas.offsetWidth
  const canvasHeight = canvas.offsetHeight
  const ratio = canvasWidth / canvasHeight

  /**
   * Objects
   */

  const meshes = getMeshes({
    height: 1,
    width: 2,
    depth: 2,
    outer: 0.05,
    wallColor: 0xffffff,
    wallDarkerColor: 0xeaeaea,
    floorColor: 0xdadada,
    innerColor: 0x444444,
    floorTexture: floorTexture
  })

  Object.values(meshes).forEach(mesh => scene.add(mesh))

  /**
   * Camera
   */

  let camera = new THREE.PerspectiveCamera(30, ratio, 0.1, 1000)

  camera.position.y = 2.5
  camera.position.z = 4.5
  camera.rotation.x = deg(-32)

  /**
   * Renderer
   */

  let renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true,
    alpha: true
  })

  renderer.setSize(canvasWidth, canvasHeight)
  renderer.render(scene, camera)

  return () => {
    floorTexture.needsUpdate = true
    renderer.render(scene, camera)
  }
}

import React from "react"

import SectionBanner from "../SectionBanner/SectionBanner"
import ImageGallery from "react-image-gallery"
import Image from "../Image/Image"

import "./Gallery.styles.css"
import "react-image-gallery/styles/css/image-gallery.css"

const Gallery = ({ items }) => {
  return (
    <SectionBanner>
      <ImageGallery
        items={items.map((o) => ({ ...o, thumbnail: o.image }))}
        showBullets={false}
        showThumbnails={false}
        showPlayButton={false}
        showFullscreenButton={false}
        renderItem={(props) => (
          <div className="gallery--item">
            <Image
              className="gallery--item--image"
              style={{ display: "block" }}
              sizes={props.sizes}
            />
            {!!props.description && (
              <p className="gallery--item--description">{props.description}</p>
            )}
          </div>
        )}
      />
    </SectionBanner>
  )
}

export default Gallery

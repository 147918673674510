import React from "react"

import {
  withGoogleMap,
  withScriptjs,
  GoogleMap,
  Marker
} from "react-google-maps"

import { compose, withProps } from "recompose"

const Map = ({ position }) => {
  const pos = {
    lat: parseFloat(position.lat),
    lng: parseFloat(position.lon)
  }

  return (
    <GoogleMap defaultZoom={15} defaultCenter={pos}>
      <Marker position={pos} />
    </GoogleMap>
  )
}

export default compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyCrkbZySPMGXvJk7HzfYcgmDxdYnssQAF8&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <div style={{ height: `320px` }} />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(Map)

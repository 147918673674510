import "./Select.styles.css"
import React from "react"
import Downshift from "downshift"

import cc from "classcat"

/**
|--------------------------------------------------
| Helpers
|--------------------------------------------------
*/

const itemToString = (isString) => (x) => {
  return isString ? x : x ? x.label : "null"
}

const key = (isString, item) => (isString ? item : item.value)

const label = (isString, value, placeholder) =>
  isString ? value : value && value.value !== null ? value.label : null

const sectionTitleEl = (isString, items, item, index) =>
  !isString &&
  index > 0 &&
  items[index - 1].section !== item.section &&
  item.section !== undefined && (
    <p className="select--options--section--section-title">{item.section}</p>
  )

/**
|--------------------------------------------------
| Main Component
|--------------------------------------------------
*/

const Select = ({
  items = [],
  value,
  style,
  placeholder,
  isString,
  onChange,
}) => {
  return (
    <Downshift onChange={onChange} itemToString={itemToString(isString)}>
      {({
        getItemProps,
        isOpen,
        selectedItem,
        highlightedIndex,
        getToggleButtonProps,
      }) => (
        <div className="select" style={style}>
          <button className="select--button" {...getToggleButtonProps()}>
            {label(isString, value) || placeholder || "…"}
          </button>

          {isOpen && (
            <div className="select--options">
              {items.map((item, index) => (
                <div
                  key={key(isString, item)}
                  className="select--options--item-wrapper"
                >
                  {sectionTitleEl(isString, items, item, index)}
                  <div
                    {...getItemProps({ item })}
                    className={cc({
                      "select--options--item": true,
                      "m--selected": selectedItem === item,
                      "m--highlighted": highlightedIndex === index,
                    })}
                  >
                    {label(isString, item)}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </Downshift>
  )
}

export default Select

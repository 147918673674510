import "./SimulatorCanvas.styles.css"
import React, { Component } from "react"
import { setupTiledCanvas, setupRoomCanvas } from "./SimulatorCanvas.utils"

/**
 * Main Component
 */

class SimulatorCanvas extends Component {
  ref = null
  tiledCanvas = null

  start = () => {
    this.tiledCanvas = setupTiledCanvas(
      {
        width: 2,
        depth: 2,
        cornerImage: this.props.corner,
        sideImage: this.props.side,
        centerImage: this.props.center,
        rotate: this.props.rotate
      },
      () => {
        setupRoomCanvas(this.props, this.ref, this.tiledCanvas)
      }
    )
  }

  componentDidMount() {
    this.start()
  }

  componentDidUpdate() {
    this.start()
  }

  render() {
    return (
      <div className="simulator-canvas">
        <canvas
          className="simulator-canvas--canvas"
          ref={ref => (this.ref = ref)}
        />
      </div>
    )
  }
}

export default SimulatorCanvas

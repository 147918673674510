import React from "react"
import Wrapper from "../Wrapper/Wrapper"
import Link from "gatsby-link"
import HamburgerMenu from "react-hamburger-menu"
import cc from "classcat"
import logoUrl from "./images/logo.svg"

class LayoutHeader extends React.Component {
  state = {
    isMenuOpen: false,
  }

  componentDidUpdate(prevProps) {
    if (this.props.path !== prevProps.path) {
      this.setState({ isMenuOpen: false })
    }
  }

  render() {
    const { pages } = this.props
    const { isMenuOpen } = this.state
    return (
      <Wrapper>
        <header
          className={cc({
            "layout--header": true,
            "s--menu-open": isMenuOpen,
          })}
        >
          <h1 className="layout--header--title">
            <Link to="/" className="layout--header--link">
              <img
                className="layout--header--logo"
                src={logoUrl}
                alt="casa franceza"
              />
            </Link>
          </h1>

          <div className="layout--header--nav-button">
            <HamburgerMenu
              isOpen={isMenuOpen}
              menuClicked={() => this.setState({ isMenuOpen: !isMenuOpen })}
              width={30}
              height={20}
              strokeWidth={3}
              rotate={0}
              color="white"
              borderRadius={0}
              animationDuration={0.5}
            />
          </div>

          <nav className="layout--header--nav">
            <ul className="layout--header--nav-list">
              {pages.map((page) => (
                <li key={page.slug} className="layout--header--nav-item">
                  <Link
                    to={`/${page.slug}`}
                    activeClassName="m--active"
                    className="layout--header--nav-item--link"
                  >
                    {page.name}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
        </header>
      </Wrapper>
    )
  }
}

export default LayoutHeader

import "./Simulator.styles.css"
import React, { Component } from "react"
import PropTypes from "prop-types"

import SectionBanner from "../SectionBanner/SectionBanner"
import SimulatorCanvas from "./components/SimulatorCanvas/SimulatorCanvas"
import SimulatorPainter from "./components/SimulatorPainter/SimulatorPainter"
import cc from "classcat"
import debounce from "lodash/debounce"

class Simulator extends Component {
  static propTypes = {
    colors: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        color: PropTypes.string
      })
    ).isRequired,
    tiles: PropTypes.arrayOf(
      PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          rotate: PropTypes.boolean,
          url: PropTypes.string
        })
      )
    ).isRequired
  }

  state = {
    isResizing: false,
    activeTileImages: { 0: "", 1: "", 2: "" },
    activeTiles: { 0: 0, 1: 0, 2: 0 },
    activePainter: 0,
    activeColor: 0
  }

  onResizeFinish = debounce(() => {
    this.setState({ isResizing: false })
  }, 500)

  onResizeStart = () => {
    this.setState({ isResizing: true })
    this.onResizeFinish()
  }

  componentDidMount() {
    const { tiles, colors } = this.props
    const { activeTiles } = this.state

    if (tiles) {
      this.setState({
        activeTileImages: [
          tiles[0][activeTiles[0]].url,
          tiles[1][activeTiles[1]].url,
          tiles[2][activeTiles[2]].url
        ]
      })
    }
    if (colors && colors.length) {
      this.setActiveColor(colors[0])
    }

    window.addEventListener("resize", this.onResizeStart)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.onResizeStart)
  }

  onPainterChange = encodedSvg => {
    this.setState(state => ({
      activeTileImages: {
        ...state.activeTileImages,
        [state.activePainter]: encodedSvg
      }
    }))
  }

  changeActiveTile = (index, offset) => () => {
    const { tiles } = this.props
    this.setState(state => {
      const cur = state.activeTiles[index]
      const max = this.props.tiles[index].length - 1
      const next = cur + offset
      const parsedNext = next > max ? 0 : next < 0 ? max : next
      return {
        activeTiles: {
          ...state.activeTiles,
          [index]: parsedNext
        },
        activeTileImages: {
          ...state.activeTileImages,
          [index]: tiles[index][parsedNext].url
        }
      }
    })
  }

  setActivePainter = activePainter => {
    this.setState({ activePainter })
  }

  setActiveColor = activeColor => {
    this.setState({ activeColor })
  }

  render() {
    const { tiles, colors } = this.props
    const {
      isResizing,
      activeTiles,
      activeTileImages,
      activePainter,
      activeColor
    } = this.state

    return (
      <SectionBanner title="Simulador">
        <section className="simulator-wrapper">
          <div className="simulator">
            <div className="simulator--controls">
              {/** Selector **/}
              <div className="simulator--controls--item">
                <div className="simulator--selector">
                  {tiles.map((typeTiles, i) => (
                    <div key={i} className="simulator--selector--item-wrapper">
                      <div className="simulator--selector--item-inner-wrapper">
                        <div
                          className="simulator--selector--arrow m--left"
                          onClick={this.changeActiveTile(i, -1)}
                        />
                        <div
                          onClick={() => this.setActivePainter(i)}
                          className={cc({
                            "simulator--selector--item": true,
                            "m--active": i === activePainter
                          })}
                        >
                          <img
                            className="simulator--selector--item--image"
                            src={activeTileImages[i]}
                          />
                        </div>
                        <div
                          className="simulator--selector--arrow m--right"
                          onClick={this.changeActiveTile(i, 1)}
                        />
                      </div>

                      <div className="simulator--label">
                        <p className="simulator--label--header">
                          {i === 0 ? "Decorado" : i === 1 ? "Canto" : "Faixa"}
                        </p>
                        <p className="simulator--label--title">
                          {typeTiles[activeTiles[i]].name}
                        </p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              {/** Painter **/}
              <div className="simulator--controls--item">
                <div className="simulator--painter">
                  <div className="simulator--painter--main">
                    <SimulatorPainter
                      color={`#${activeColor && activeColor.color}`}
                      activeImage={activePainter}
                      images={tiles.map(
                        (tiles, i) => tiles[activeTiles[i]].url
                      )}
                      onChange={this.onPainterChange}
                    />
                  </div>

                  <div className="simulator--label">
                    <p className="simulator--label--header">Cor</p>
                    <p className="simulator--label--title">
                      {activeColor && activeColor.name}
                    </p>
                  </div>

                  <div className="simulator--painter--colors">
                    {colors.map((color, i) => (
                      <div
                        key={color.color}
                        title={color.name}
                        style={{ backgroundColor: `#${color.color}` }}
                        className={cc({
                          "simulator--painter--colors--item": true,
                          "m--active": color === activeColor
                        })}
                        onClick={() => this.setActiveColor(color)}
                      />
                    ))}
                  </div>
                  <div className="simulator--painter--disclaimer">
                    Por razões técnicas, só executamos ladrilhos com até 7 cores
                    diferentes.
                  </div>
                </div>
              </div>
            </div>

            <div className="simulator--canvas">
              {!isResizing && (
                <SimulatorCanvas
                  center={activeTileImages[0]}
                  corner={activeTileImages[1]}
                  side={activeTileImages[2]}
                  rotate={tiles[0][activeTiles[0]].rotate}
                />
              )}
            </div>
          </div>
        </section>
      </SectionBanner>
    )
  }
}

export default Simulator

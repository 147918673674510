import React, { Component } from "react"
import { graphql } from "gatsby"
import Layout from "../layouts/"

import Content from "../components/Content/Content"
import Gallery from "../components/Gallery/Gallery"
import Map from "../components/Map/Map"
import Simulator from "../components/Simulator/Simulator"
import Catalog from "../components/Catalog/Catalog"

import Wrapper from "../components/Wrapper/Wrapper"
import SectionBanner from "../components/SectionBanner/SectionBanner"
import Modal from "../components/Modal/Modal"
import FormContact, {
  FormContactTrigger,
} from "../components/FormContact/FormContact"

/**
 * Helpers
 */

const helmetTitle = (site, page) =>
  page.seoTitle
    ? `${site.title} | ${page.seoTitle}`
    : page.name !== "Home"
    ? `${site.title} | ${page.name}`
    : site.title

const parseCatalogItem = (type) => (edge) => ({
  title: edge.node.name,
  sizes: edge.node.image.sizes,
  properties:
    type === "Granilite"
      ? {
          Tipo: edge.node.type,
          Cores: edge.node.color,
        }
      : type === "Ladrilho"
      ? {
          Tipo: edge.node.type,
          Tamanho: edge.node.size,
        }
      : {},
})

const validCatalogItem = (edge) => !!edge.node.image

const toLocalTiles = (type, tiles) =>
  tiles.map((tile) => ({
    name: tile.title,
    rotate: tile.rotate,
    url: `/static/tiles/${type}/${tile.file.url.split("/").slice(-1)}`,
  }))

const toCenterTiles = (simulator) =>
  simulator.tilesCenter.map((tile) => ({
    ...tile,
    rotate: simulator.centerTilesWithRotation.indexOf(tile.title) !== -1,
  }))

/**
 * Main Component
 */

class Page extends Component {
  state = {
    isContactFormOpen: false,
    isBannerOpen: true,
  }

  onOpenContactForm = () => {
    this.setState({ isContactFormOpen: true })
  }

  onCloseContactForm = () => {
    this.setState({ isContactFormOpen: false })
  }

  onCloseBanner = () => {
    this.setState({ isBannerOpen: false })
  }

  render() {
    const { isContactFormOpen, isBannerOpen } = this.state

    const { data, location } = this.props
    const {
      contentfulMain: site,
      contentfulSimulator: simulator,
      contentfulPage: page,
      allContentfulProductGranilite: productGranilites,
      allContentfulProductLadrilho: productLadrilhos,
    } = data

    const catalog = {
      Granilite: productGranilites.edges
        .filter(validCatalogItem)
        .map(parseCatalogItem("Granilite")),
      Ladrilho: productLadrilhos.edges
        .filter(validCatalogItem)
        .map(parseCatalogItem("Ladrilho")),
    }

    if (!site || !page) return <div />

    return (
      <Layout site={site} page={page} location={location}>
        {page.body && page.body.body && (
          <Wrapper>
            <Content
              title={page.name}
              content={page.body.body}
              files={
                page.files &&
                page.files.map((o) => ({
                  title: o.title,
                  url: o.file.url,
                }))
              }
            />
          </Wrapper>
        )}

        {page.gallery && page.gallery.length && (
          <Gallery
            items={page.gallery.map((o) => ({
              sizes: o.sizes,
              description: o.title,
            }))}
          />
        )}

        {page.displayContactWidgets && (
          <div>
            <FormContactTrigger onClick={this.onOpenContactForm} />

            <SectionBanner>
              <Map position={site.contactLocation} />
            </SectionBanner>

            {isContactFormOpen && (
              <Modal onDismiss={this.onCloseContactForm}>
                <FormContact onDismiss={this.onCloseContactForm} />
              </Modal>
            )}
          </div>
        )}

        {page.displaySimulator && (
          <Simulator
            colors={simulator.colors}
            tiles={[
              toLocalTiles("center", toCenterTiles(simulator)),
              toLocalTiles("corner", simulator.tilesCorner),
              toLocalTiles("side", simulator.tilesSide),
            ]}
          />
        )}

        {page.catalog && <Catalog items={catalog[page.catalog]} />}

        {page.banner && isBannerOpen && (
          <Modal onDismiss={this.onCloseBanner}>
            <a
              alt={page.banner.name}
              href={page.banner.link}
              target="_blank"
              rel="noopener noreferrer"
              style={{
                display: "block",
                width: "100%",
                maxWidth: 900,
                overflow: "auto",
              }}
            >
              <img
                src={page.banner.image.file.url}
                title={page.banner.name}
                alt={page.banner.name}
                style={{
                  maxWidth: "100%",
                  height: "auto",
                  float: "left",
                }}
              />
            </a>
          </Modal>
        )}
      </Layout>
    )
  }
}

export default Page

export const query = graphql`
  query pageQuery($id: String!) {
    contentfulMain(name: { eq: "Main" }) {
      title
      contactAddressLineOne
      contactAddressLineTwo
      contactEmail
      contactTelephone
      seoGoogleAnalytics
      seoGoogleTagManager
      pages {
        name
        slug
      }
      contactLocation {
        lat
        lon
      }
    }
    contentfulSimulator(name: { eq: "Main" }) {
      centerTilesWithRotation
      colors {
        name
        color
      }
      tilesCenter {
        title
        file {
          url
        }
      }
      tilesCorner {
        title
        file {
          url
        }
      }
      tilesSide {
        title
        file {
          url
        }
      }
    }
    allContentfulProductLadrilho(limit: 1000) {
      edges {
        node {
          name
          size
          type
          image {
            sizes(maxWidth: 206) {
              ...GatsbyContentfulSizes
            }
          }
        }
      }
    }
    allContentfulProductGranilite(limit: 1000) {
      edges {
        node {
          name
          type
          color
          image {
            sizes(maxWidth: 206) {
              ...GatsbyContentfulSizes
            }
          }
        }
      }
    }
    contentfulPage(id: { eq: $id }) {
      name
      body {
        body
      }
      files {
        title
        file {
          url
        }
      }
      gallery {
        title
        sizes(maxWidth: 1200) {
          ...GatsbyContentfulSizes
        }
      }
      displaySimulator
      displayContactWidgets
      catalog
      banner {
        name
        link
        image {
          file {
            url
          }
        }
      }
      seoTitle
      seoKeywords
      seoDescription {
        seoDescription
      }
    }
  }
`

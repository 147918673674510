import React from "react"
import GatsbyImage from "gatsby-image"

export default function Image({
  objFit = `cover`,
  objPosition = `50% 50%`,
  ...props
}) {
  return (
    <GatsbyImage
      {...props}
      imgStyle={{
        ...props.imgStyle,
        objectFit: objFit,
        objectPosition: objPosition,
        fontFamily: `"object-fit: ${objFit}; object-position: ${objPosition}"`
      }}
    />
  )
}

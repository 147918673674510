import "./Content.styles.css"
import React from "react"
import Markdown from "react-markdown"

const Content = ({ title, content, files }) => {
  return (
    <section className="content">
      <h1 className="content--title">{title}</h1>
      <Markdown className="content--body">{content}</Markdown>
      {files && files.length && (
        <ul className="content--files">
          {files.map((file, i) => (
            <li key={i} className="content--files--item">
              <a
                className="content--files--item--link"
                href={file.url}
                target="_blank"
                rel="noopener noreferrer"
              >
                {file.title}
              </a>
            </li>
          ))}
        </ul>
      )}
    </section>
  )
}

export default Content

import "./SimulatorPainter.styles.css"
import React, { Component } from "react"
import PropTypes from "prop-types"
import cc from "classcat"

class SimulatorPainter extends Component {
  refs_0 = null
  refs_1 = null
  refs_2 = null

  static propTypes = {
    color: PropTypes.string.isRequired,
    activeImage: PropTypes.number,
    images: PropTypes.arrayOf(PropTypes.string).isRequired,
    onChange: PropTypes.func.isRequired
  }

  componentDidMount() {
    this.setup(this.refs_0)
    this.setup(this.refs_1)
    this.setup(this.refs_2)
  }

  componentDidUpdate(prevProps) {
    this.props.images.forEach((o, i) => {
      if (prevProps.images[i] !== this.props.images[i]) {
        this.setup(this[`refs_${i}`])
      }
    })
  }

  setup = ref => {
    if (!ref) return

    ref.addEventListener("load", () => {
      /**
       * Setup listeners
       */

      const document =
        ref.contentDocument || (ref.getSVGDocument && ref.getSVGDocument())
      const svg = document && document.querySelector("svg")
      const children = svg ? svg.querySelectorAll("[fill]") : []

      children.forEach(child =>
        child.addEventListener("click", () => {
          /**
           * Paint
           */

          child.setAttribute("fill", this.props.color)

          /**
           * Create new encoded svg with changes,
           * call onChange listener.
           */

          this.props.onChange &&
            this.props.onChange(
              `data:image/svg+xml; charset=utf8, ${encodeURIComponent(
                new XMLSerializer().serializeToString(svg)
              )}`
            )
        })
      )
    })
  }

  render() {
    return (
      <div className="simulator-painter-wrapper">
        {this.props.images.map((o, i) => (
          <embed
            key={o}
            ref={ref => {
              this[`refs_${i}`] = ref
            }}
            src={o}
            type="image/svg+xml"
            width="200"
            height="200"
            className={cc({
              "simulator-painter": true,
              "m--active": i === this.props.activeImage
            })}
          />
        ))}
      </div>
    )
  }
}

export default SimulatorPainter

import "./FormContact.styles.css"
import React, { Component } from "react"
import Wrapper from "../Wrapper/Wrapper"
import Select from "../Select/Select"
import axios from "axios"

export const FormContactTrigger = ({ onClick }) => (
  <Wrapper>
    <div className="form-contact-trigger">
      <button className="form-contact-trigger__button" onClick={onClick}>
        Entrar em contato
      </button>
    </div>
  </Wrapper>
)

export default class FormContact extends Component {
  state = {
    name: "",
    tel: "",
    email: "",
    subject: "Orçamento",
    message: "",
    isLoading: false,
    isComplete: false,
  }

  onUpdateField = (field) => (e) => {
    this.setState({ [field]: e.target.value })
  }

  onUpdateSubject = (subject) => {
    this.setState({ subject })
  }

  onSubmit = (e) => {
    const { name, tel, email, subject, message } = this.state

    e.preventDefault()
    this.setState({ isLoading: true })

    axios
      .post("https://us-central1-casa-franceza.cloudfunctions.net/contact/", {
        name,
        tel,
        email,
        subject,
        message,
      })
      .then((res) => {
        this.setState({ isComplete: true })
      })
      .catch((err) => {
        this.setState({ isComplete: true })
      })
  }

  onClose = () => {
    this.props.onDismiss && this.props.onDismiss()
  }

  render() {
    const {
      name,
      tel,
      email,
      subject,
      message,
      isLoading,
      isComplete,
    } = this.state

    return (
      <div className="form-contact-wrapper">
        {!isComplete ? (
          <form className="form-contact" onSubmit={this.onSubmit}>
            {isLoading && <div className="form-contact__loading" />}

            <h1 className="form-contact__title">Contato</h1>
            <p className="form-contact__disclaimer">
              Deixe sua mensagem – caso apropriado, entraremos em contato assim
              que possível.
            </p>
            <p className="form-contact__field-wrapper">
              <label htmlFor="name" className="form-contact__label">
                Nome
              </label>
              <input
                id="name"
                className="form-contact__input"
                type="text"
                placeholder="…"
                onChange={this.onUpdateField("name")}
                value={name}
              />
            </p>
            <p className="form-contact__field-wrapper">
              <label htmlFor="telephone" className="form-contact__label">
                Telefone
              </label>
              <input
                id="telephone"
                className="form-contact__input"
                type="text"
                placeholder="…"
                onChange={this.onUpdateField("tel")}
                value={tel}
              />
            </p>
            <p className="form-contact__field-wrapper">
              <label htmlFor="email" className="form-contact__label">
                E-mail
              </label>
              <input
                id="email"
                className="form-contact__input"
                type="email"
                placeholder="…"
                onChange={this.onUpdateField("email")}
                value={email}
              />
            </p>
            <p className="form-contact__field-wrapper">
              <label htmlFor="subject" className="form-contact__label">
                Assunto
              </label>
              <Select
                id="subject"
                style={{ width: "100%", border: "1px solid #dadada" }}
                isString
                value={subject}
                onChange={this.onUpdateSubject}
                items={["Orçamento", "Dúvidas", "Sugestões"]}
              />
            </p>
            <p className="form-contact__field-wrapper">
              <label htmlFor="message" className="form-contact__label">
                Mensagem
              </label>
              <textarea
                id="message"
                required
                className="form-contact__input m--textarea"
                placeholder="…"
                onChange={this.onUpdateField("message")}
                value={message}
              />
            </p>
            <p className="form-contact__field-wrapper">
              <button className="form-contact__submit-button" type="submit">
                enviar
              </button>
            </p>
          </form>
        ) : (
          <div className="form-contact__complete">
            <p className="form-contact__complete__disclaimer">
              Sua mensagem foi enviada com sucesso! Obrigado pelo contato.
            </p>
            <button
              className="form-contact__complete__button"
              onClick={this.onClose}
            >
              Ok
            </button>
          </div>
        )}
      </div>
    )
  }
}

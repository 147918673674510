import "./Catalog.styles.css"

import React, { Component } from "react"
import Image from "../Image/Image"
import Section from "../Section/Section"
import Select from "../Select/Select"
import LazyLoad from "react-lazyload"

import debounce from "lodash/debounce"
import flow from "lodash/flow"

/**
|--------------------------------------------------
| Helpers
|--------------------------------------------------
*/

const aggregateProperties = flow(
  items =>
    items.reduce(
      (acc, item) =>
        Object.entries(item.properties).reduce(
          (acc, [key, value]) => ({
            ...acc,
            [key]: value.reduce
              ? value.reduce(
                  (acc, value) => ({
                    ...acc,
                    [value]: true
                  }),
                  acc[key] || {}
                )
              : {
                  ...(acc[key] || {}),
                  [value]: true
                }
          }),
          acc
        ),
      {}
    ),
  Object.entries,
  entries => entries.map(([key, value]) => [key, Object.keys(value)])
)

const filterByModel = (item, model) =>
  !model || item.title.toLowerCase().indexOf(model.toLowerCase()) > -1

const filterByActiveProperties = (item, activeProperties) =>
  Object.entries(activeProperties).reduce(
    (acc, [key, value]) =>
      !value ||
      item.properties[key] === value ||
      (item.properties[key].indexOf &&
        item.properties[key].indexOf(value) !== -1)
        ? acc
        : false,
    true
  )

const itemSorter = (a, b) => {
  const aNumber = parseFloat(a.title)
  const bNumber = parseFloat(b.title)

  if (!isNaN(aNumber) && !isNaN(bNumber)) {
    return aNumber - bNumber
  } else if (isNaN(aNumber) && isNaN(bNumber)) {
    return a.title.localeCompare(b.title)
  } else {
    return isNaN(aNumber) ? 1 : -1
  }
}

/**
|--------------------------------------------------
| Main Component
|--------------------------------------------------
*/

class Catalog extends Component {
  state = {
    model: "",
    activeProperties: {},
    properties: []
  }

  onUpdateModel = debounce(model => {
    this.setState({ model })
  }, 200)

  onUpdateProperty = key => value => {
    this.setState(state => ({
      activeProperties: {
        ...state.activeProperties,
        [key]: value !== "Todos" ? value : ""
      }
    }))
  }

  componentDidMount = () => {
    const properties = aggregateProperties(this.props.items || [])
    this.setState({
      properties,
      activeProperties: properties.reduce(
        (acc, [key]) => ({
          ...acc,
          [key]: ""
        }),
        {}
      )
    })
  }

  render() {
    const { items } = this.props
    const { model, properties, activeProperties } = this.state

    console.log(items.map(o => o.title).join(","))

    const filteredItems = items
      ? items
          .filter(
            item =>
              filterByModel(item, model) &&
              filterByActiveProperties(item, activeProperties)
          )
          .sort(itemSorter)
      : []

    return (
      <Section
        stickyHeader
        title="Catálogo"
        headerContent={
          <section className="catalog--header">
            {properties.map(([key, values]) => (
              <div key={key} className="catalog--field">
                <label className="catalog--field--label">{key}</label>
                <Select
                  isString
                  placeholder="Todos"
                  value={activeProperties[key]}
                  onChange={this.onUpdateProperty(key)}
                  items={["Todos"].concat(values)}
                />
              </div>
            ))}

            <div className="catalog--field">
              <label className="catalog--field--label" htmlFor="textfield">
                Modelo
              </label>
              <input
                id="textfield"
                className="catalog--input"
                placeholder="…"
                onKeyUp={e => {
                  this.onUpdateModel(e.target.value)
                }}
              />
            </div>
          </section>
        }
      >
        {!!filteredItems.length ? (
          <ul className="catalog--list">
            {filteredItems.map(item => (
              <li key={item.title} className="catalog--item-wrapper">
                <div className="catalog--item">
                  <div className="catalog--item--image-wrapper">
                    <LazyLoad height={206} once offset={400}>
                      <Image
                        className="catalog--item--image"
                        alt={item.title}
                        sizes={item.sizes}
                      />
                    </LazyLoad>
                  </div>
                  <div className="catalog--item--info">
                    <p className="catalog--item--header">#</p>
                    <p className="catalog--item--label">{item.title}</p>
                    <p className="catalog--item--alt">
                      {item.properties && item.properties.Tipo}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="catalog--empty">Nenhum item encontrado.</p>
        )}
      </Section>
    )
  }
}

export default Catalog
